// Vis Timeline
@import "./components/styles/vis.styles.scss";
// Big Calendar
@import "react-big-calendar/lib/sass/styles";
@import "react-big-calendar/lib/addons/dragAndDrop/styles"; // if using DnD
@import "./components/styles/big-calendar.styles.scss";
// Quil
@import "react-quill/dist/quill.snow.css";
@import "./components/styles/quill.styles.scss";

* {
  font-family: "Figtree", "Helvetica", "Arial", sans-serif;
}

img,
svg {
  vertical-align: middle;
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

.scrollbar-slick {
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateZ(0);

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-button:end:increment,
  &::-webkit-scrollbar-button:start:decrement {
    background: #0000;
    display: none;
  }

  &::-webkit-scrollbar-track-piece {
    background: var(--ds-background-neutral, #091e4214);
  }

  &::-webkit-scrollbar-track-piece:vertical:start {
    border-radius: 4px 4px 0 0;
  }

  &::-webkit-scrollbar-track-piece:vertical:end {
    border-radius: 0 0 4px 4px;
  }

  &::-webkit-scrollbar-track-piece:horizontal:start {
    border-radius: 4px 0 0 4px;
  }

  &::-webkit-scrollbar-track-piece:horizontal:end {
    border-radius: 0 4px 4px 0;
  }

  &::-webkit-scrollbar-thumb:horizontal,
  &::-webkit-scrollbar-thumb:vertical {
    background: var(--ds-background-neutral-hovered, #091e4221);
    border-radius: 4px;
    display: block;
    height: 48px;
  }
}

// TODO | To be added in MUI
@keyframes pulse {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  50% {
    transform: scale(1.15) translate(-43.5%, -43.5%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
