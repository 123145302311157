.ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;

  &.ql-snow {
    border: none;
    border-bottom: 1px solid #e9ebeb;
    background-color: #fff;
  }
}

.ql-container {
  margin: 0 auto 0;

  &.ql-snow {
    border: none;
  }
}

.ql-editor {
  font-size: 16px;
}
