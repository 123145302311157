.vis-timeline,
.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top,
.vis-group,
.vis-label {
  border-color: #e9ebeb !important;
}

.vis-label {
  display: flex;
  align-items: center;
  color: #252525 !important;
  font-size: 14px;
  padding-left: 3px;
  padding-right: 20px;

  .vis-inner {
    display: flex !important;
    align-items: center;
    min-height: 49px;
  }
}

.vis-major,
.vis-minor {
  color: #757575 !important;
}

.vis-major {
  font-size: 14px;
  font-weight: 700;
}

.vis-minor {
  font-size: 13px;
}

.vis-item {
  border: 1px solid #0775da;
  background-color: #0775da;
  cursor: pointer;
  height: 36px;
  padding-left: 4px;
  padding-right: 4px;
  color: #fff;

  &.vis-range,
  &.vis-box {
    border-radius: 4px;
  }

  &.vis-selected {
    border: 1px solid #0775da;
    background-color: #fff;
    color: #252525;
  }
}

.vis-loading-screen {
  border: 1px solid #e9ebeb;
  // padding: 15px;

  div {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);

    animation: skeleton 1.5s ease-in-out 0.5s infinite;
  }
}

// TODO | Extract from MUI
@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
