.rbc-calendar {
  color: #252525;
}

.rbc-toolbar button,
.rbc-month-view,
.rbc-day-bg,
.rbc-month-row,
.rbc-timeslot-group,
.rbc-time-content > * + * > *,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-color: #e9ebeb !important;
}

.rbc-current-time-indicator {
  height: 2px;
  margin-top: -1px;
  background-color: #ff7f6e;
}

.rbc-header {
  padding: 8px 4px;
  color: #fff;
  background-color: #0775da;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
}

.rbc-toolbar {
  border: 1px solid #e9ebeb;
  border-bottom: none;
  padding: 8px 10px;
  margin-bottom: 0;

  .rbc-toolbar-label {
    color: #252525;
    font-size: 16px;
    font-weight: 600;
  }

  button {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
  }

  button:active:hover,
  button.rbc-active:hover,
  button:hover {
    background-color: #fff;
    color: #0575da;
  }

  button:active,
  button:active:focus,
  button.rbc-active,
  button.rbc-active:focus,
  button:focus {
    box-shadow: none;
    background-color: #0575da;
    color: #fff;
  }
}

.rbc-time-header-cell {
  .rbc-button-link {
    font-weight: 700;
  }
}

.rbc-off-range-bg {
  background-color: #fff;
}

.rbc-off-range {
  .rbc-button-link {
    opacity: 0.3;
  }
}

// Off Range
.rbc-month-header,
.rbc-time-header-cell {
  & > .rbc-header:first-of-type,
  & > .rbc-header:last-of-type {
    background-color: #cecece;
  }
}

.rbc-month-row {
  .rbc-row-bg > .rbc-day-bg:first-of-type,
  .rbc-row-bg > .rbc-day-bg:last-of-type {
    background-color: #f8f8f8;
  }
}

.rbc-date-cell {
  .rbc-button-link {
    font-size: 16px;
    font-weight: 600;
  }
}

.rbc-today {
  background-color: #d7e5f8;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border-color: #d7e5f8;
}

.rbc-event-label {
  font-size: 10px;
  margin-bottom: 1px;
}

.rbc-event-content {
  font-size: 14px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #0575da;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-label {
  font-size: 14px;
}
